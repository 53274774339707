.tg {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0px auto;
}

.tg td {
    font-family: Arial, sans-serif;
    font-size: 14px;
    padding: 10px 5px;
    border-style: solid;
    border-width: 0px;
    overflow: hidden;
    word-break: normal;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-color: black;
}

.tg th {
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    padding: 10px 5px;
    border-style: solid;
    border-width: 0px;
    overflow: hidden;
    word-break: normal;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-color: black;
}

.tg .tg-0lax {
    border-color: inherit;
    text-align: left;
    vertical-align: top
}

.changelog {
    padding-top: 2vh;
    width: 60vw;
}

h4 {
    text-align: justify;
}