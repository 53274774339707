.vid-bg {
    position: fixed;
    min-width: 100%;
}

#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}

.construction {
    position: relative;
    top: 20vh;
}