.race-add {
    padding-top: 2vh;
    padding-bottom: 2vh;
    max-width: 1000px;
    margin: 0 auto;
}

.outer {
    align-items: center;
    justify-content: center;
    display: flex;
}