.App {
  text-align: center;
  background-color: #121212;
  min-height: 100vh;
  color: white;
  font-size: calc(10px + 2vmin);
  /*font-family: F1;*/
}

html:before {
  content: '';
  position: fixed;
  width: 100%;
  height: 1000px;
  z-index: -1;
  bottom: 0;
  left: 0;
  background-color: #121212;
}

.App-logo {
  height: 16vh;
  max-width: 90%;
}

.App-header {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1vmin;
  padding-bottom: 1vmin;
  /* justify-content: center; */
}

.App-nav {
  text-align: center;
  font-size: medium;
  max-width: 100vw;
}

.App-links {
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vmin;
}

.user-text-nav {
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vmin;
}

.Account {

}

.content {
  position: relative;
  padding-top: 20vh;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#selectCol {
  width: 200px;
  min-width: 200px;
}

.predictionPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 98vw;
  overflow-x: auto;
  margin-bottom: 8vh;
  /*background-color: #181818;*/
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
}

#archive-panel {
  max-width: 98vw;
  overflow-x: auto;
  margin-bottom: 8vh;
}

#userScore {
  outline-style: solid;
  padding: 0.5vh;
}

.navRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #1e1e1e;
  flex-wrap: wrap;
}

.App-links:hover {
  color: red;
}

#driverName {
  /*padding-left: 2%;*/
  /*position: absolute;*/
  padding: 0 0;
  text-align: left;
  margin-left: 5%;
}

#fname {
  font-family: "Formula1 Display-Regular", sans-serif;
  font-size: x-small;
}

#lname {
  font-family: "Formula1 Display-Bold", sans-serif;
  font-size: small;
  /*max-font-size: 10%;*/
}

@font-face {
  font-family: "Formula1 Display-Bold";
  src: local("Formula1 Display-Bold"), url("fonts/Formula1-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Formula1 Display-Regular";
  src: local("Formula1 Display-Regular"), url("fonts/Formula1-Regular.ttf") format("truetype");
}

#driverImg {
  height: 98%;
  position: absolute;
  right: 2%;
  bottom: 0
}

.driverOuter {
  display: flex;
  overflow: hidden;
  height: max(3vw, 60px);
  position: relative;
  align-items: center;
  align-content: center;
  /*display: flex;*/
  /*flex-direction: row;*/
  /*padding: 8px;*/
}

.predictNav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.driverContainer {
  border-radius: 3px;
}

.predict {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.dropBox {
  /*width: 16vw;*/
  min-width: 200px;
  text-align: left;
}

#circuitMap {
  width: 15vw;
  /*background-color: #d9d9d9;*/
  /*border-radius: 10%;*/
  min-width: 200px;
  /*padding: 10vh;*/
}

.archive {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.column {
  display: flex;
  flex-direction: column;
  padding: 1vh;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rules {
  width: 70vw;
}

.outer {
  align-items: center;
  justify-content: center;
  display: flex;
}

#footer {
  position: relative;
  z-index: 0;
  /*bottom: 40px;*/
  /*left: 50%;*/
  width: 100%;
  height: 20px;
  font-size: small;
  color: darkgray;
  margin: 0 auto;
}

#submit-bar {
  position: fixed;
  bottom: 0;
  z-index: 1;
  /*margin-bottom: 1vh;*/
  background-color: #1e1e1e;
  width: 100vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@font-face {
  font-family: 'F1';
  font-style: normal;
  src: local('F1'), url(./fonts/Formula1-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'F1-bold';
  src: local('F1'), url(./fonts/Formula1-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'F1-wide';
  src: local('F1'), url(./fonts/Formula1-Wide.ttf) format('opentype');
}
